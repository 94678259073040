<template>
  <div class="header" :class="route.path !== '/' ? null : 'float'">
    <div class="navigation">
      <img src="@/assets/images/home/logo.png" alt="" @click="router.push({ path: '/' })"/>
      <nav>
        <router-link
          to="/"
          :class="route.path !== '/' ? null : 'selected'"
          >首页</router-link
        >
        <router-link
          to="/contributionList"
          :class="
            route.path !== '/contributionList' && route.path !== '/contribution' ? null : 'selected'
          "
          >公益项目</router-link
        >
        <template v-for="item in store.state.columns" :key="item.id">
          <router-link
            :to="{ path: '/newsList', query: { type: item.id } }"
            :class="store.state.column != item.id ? 'none' : 'selected'"
            >{{ item.name }}</router-link
          >
        </template>
      </nav>
      <router-link to="/searchNews"
        ><img src="@/assets/images/home/search.png" alt=""
      /></router-link>
    </div>
  </div>
  <router-view />
  <div class="footer">
      <div class="left">
        <p>联系我们</p>
        <div class="tele">
          <img src="@/assets/images/home/24gf-telephone.png" alt="" />
          <span>0791-86701560</span>
        </div>
        <span>地址:江西省南昌市东湖区叠山路511号原省政协大楼9楼</span>
        <div class="address">
          <img src="@/assets/images/home/liuyanxiaoxi.png" alt="" />
          <span>我要捐款</span>
        </div>
        <span>户&nbsp;&nbsp;名: 江西省青少年发展基金会</span>
        <span>开&nbsp;户&nbsp;行: 中国银行南昌市八一支行</span>
        <span>银行账号: 191705976554</span>
        <span>信息产业部备案编号: 赣B220050065号</span>
        <span>版权所有：江西省青少年发展基金会</span>
      </div>
      <div class="center">
        <div class="navigation">
          <p>快速链接</p>
          <ul>
            <li>
              <router-link to="/contributionList"
                ><span>公益项目</span></router-link
              >
            </li>
            <li v-for="item in store.state.columns" :key="item.id">
              <router-link :to="{ path: '/newsList', query: { type: item.id } }"
                ><span>{{ item.name }}</span></router-link
              >
            </li>
          </ul>
          <p>友情链接</p>
          <ul>
            <li><a href="https://www.gqt.org.cn/"><span>共青团中央</span></a></li>
            <li><a href="https://www.jxyouth.org.cn/"><span>共青团江西省委</span></a></li>
            <li><a href="https://cszg.mca.gov.cn/"><span>慈善中国</span></a></li>
            <li><a href="https://www.qingnews.cn/#/"><span>青新闻</span></a></li>
            <li><a href="https://www.cydf.org.cn/#/"><span>中国青少年发展基金会</span></a></li>
          </ul>
        </div>
      </div>
      <div class="right">
          <div>
            <img src="@/assets/images/home/qrcode1.png" alt="" />
            <span>微信公众号</span>
          </div>
          <div>
            <img src="@/assets/images/home/qrcode2.png" alt="" />
            <span>抖音官方号</span>
          </div>
          <div>
            <img src="@/assets/images/home/qrcode3.png" alt="" />
            <span>希望工程·壹元捐</span>
          </div>
        </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getColumns } from "./api/service/newsList.js";

const route = useRoute();
const router = useRouter();
const store = useStore();

onMounted(() => {
  // 判断当前设备是否为移动设备（非PC）
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  // 判断当前是否在 PC 端
  function isPC() {
    return !isMobileDevice();
  }
  if (!isPC()) {
    window.location.href = "https://www.jxxwgc.org.cn/h5";
  }
  getColumns(0)
    .then((response) => {
      console.log(response, "新闻栏目");
      store.state.columns = response.data.res;
    })
    .catch((error) => {
      console.error(error);
    });
});
</script>

<style src="@/assets/css/reset.css"></style>
<style scoped>
.header {
  height: 90px;
}

.float {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.66);
}

.header .navigation {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

nav {
  position: absolute;
  right: 8%;
}

nav a {
  margin: 0 25px;
  line-height: 90px;
  padding-top: 32px;
  font-size: 16px;
}

.selected {
  border-top: 5px solid #e8333a;
  color: #e8333a;
}

nav a.active {
  color: #e8333a;
}

.navigation > img {
  margin-left: 20px;
}

.navigation > a {
  padding: 33px 33px 34px 34px;
  background: #e8333a;
  position: absolute;
  right: 0;
}

.navigation > a img {
  width: 23px;
  height: 22px;
}
.footer {
  height: 349px;
  background: #ed5858;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
}

.footer p {
  font-size: 22px;
  line-height: 28px;
  margin: 15px 0;
}

.footer .center p {
  margin: 30px 0;
}

.footer .center {
  width: 27%;
}

.footer .left > span,
.footer .navigation > span {
  font-size: 14px;
  line-height: 28px;
  opacity: 0.8;
  display: block;
}

.footer .left > img {
  width: 25px;
  height: 25px;
}

.footer .tele span,
.footer .address span {
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  opacity: 0.8;
  margin: 0 20px 5px 20px;
}

.footer .right {
  display: flex;
  width: 400px;
  justify-content: space-between;
}

.footer .right img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.footer .right div {
  display: block;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-self: center;
  flex-direction: column;
}

.footer li span {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
}

.footer ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.footer .right span {
  display: inline-block;
  margin-top: 10px;
  opacity: 0.8;
  line-height: 22px;
}
</style>