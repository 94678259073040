import { createStore } from 'vuex'

export default createStore({
  state: {
    sum: null,
    column: '/',
    recentlyContribution: [],
    offlineContribution: [],
    homeContributionList: [],
    carousel: [],
    newsFull: [],
    contributionFull: [],
    contribution: {},
    contributionDynamic: {
      list: [],
      total: 111
    },
    contributionMember: {
      list: [],
      total: 111
    },
    similarContribution: [],
    newsSelected: 1,
    contributionSelected: 1,
    lastYearSum: 80652320,
    lastMonthSum: 92320,
		category: 1,
  },
  getters: {
  },
  mutations: {
    // watchStage(state, list) {
		// 	for (let i = 0; i < list.length; i++) {
		// 		if (isOverGoal(list[i].success_rate, list[i].over_goal) || isEnd(list[i].end_time)) {
		// 			list[i].is_raising = '已结束'
		// 		} else {
		// 			list[i].is_raising = '募集中'
		// 		}
		// 		function isOverGoal(rate, over_goal) {
		// 			if (rate == 100 && over_goal != 1) {
		// 				console.log('已筹集')
		// 				return true
		// 			}
		// 			else return false
		// 		}
				
		// 		function isEnd(end_time) {
		// 			if (end_time < Date.now() / 1000 && end_time != 0) {
		// 				console.log('到时间')
		// 				return true
		// 			}
		// 			else return false
		// 		}
		// 	}
		// },
    watchStage(state, list) {
			list.forEach((item, index, arr) => {
				if (item.item_status === 1) {
					switch (item.item_suspend) {
						case 1:
							if (item.progress == 4) {
								item.stage = 4
							} else {
								item.stage = 1
							}
							break;
						case 2:
							if (item.progress == 4) {
								item.stage = 4
							} else {
								item.stage = 2
							}
							break;
						case 3:
							if (item.progress == 4) {
								item.stage = 4
							} else {
								item.stage = 3
							}
							break;
						default:
							break;
					}
				} else {
					item.stage = 3
				}
			})
		},
    watchType(state) {
      for (let i = 0; i < state.recentlyContribution.length; i++) {
        if (!state.recentlyContribution[i].type) {
          state.recentlyContribution[i].type = '线上捐'
        } else {
          state.recentlyContribution[i].type = '现金捐'
        }
      }
    },
    Type(state) {
      for (let i = 0; i < state.contributionMember.list.length; i++) {
        if (!state.contributionMember.list[i].type) {
          state.contributionMember.list[i].type = '线上捐'
        } else {
          state.contributionMember.list[i].type = '现金捐'
        }
      }
    },
    contributionSelect(state, id) {
      state.contributionShow = id
      state.contributionSelected = id
      // console.log(id);
    }
  },
  actions: {
  },
  modules: {
  }
})